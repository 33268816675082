<template lang="pug">
  div
    v-row
      v-col(cols="12").mb-0.pb-0
        v-autocomplete(
          label="Parent Code"
          v-model="parentCode"
          item-value="id"
          item-text="description"
          return-object
          :items="parentCodeList"
          clearable
        )
          template(v-slot:item="{ item }")
            span.primary--text {{ item.account_code }}
            small.ml-2.blue-grey--text {{ item.description }}
      v-col(cols="12").ma-0.py-1
        v-autocomplete(
          label="Account Code"
          v-model="accountCode"
          item-value="id"
          item-text="description"
          return-object
          dense
          :items="accountCodeListFiltered"
        )
          template(v-slot:item="{ item }")
            span.primary--text {{ item.account_code }}
            small.ml-2.blue-grey--text {{ item.description }}
      v-col(cols="12").ma-0.py-1
        v-text-field(
          label="Amount"
          type="number"
          dense
          v-model.number="amount"
        )
      v-col(cols="12").ma-0.py-1
        v-textarea(
          label="Reason"
          v-model="reason"
          dense
          :counter="191"
        )
</template>
<script>
import accountCodeRepository from '@/repositories/account-code.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import indexKeyGenerator from '@/libs/index-key-generator.extra'

const [accountCodeGetVars, accountCodeGetVarNames] = requestVars({ identifier: 'account-code' })
const [parentCodeGetVars, parentCodeGetVarNames] = requestVars({ identifier: 'parent-code' })

const accountCodeGetHandler = new VueRequestHandler(null, accountCodeGetVarNames)
const parentCodeGetHandler = new VueRequestHandler(null, parentCodeGetVarNames)

const inputVars = () => ({
  id: null,
  accountCode: {},
  parentCode: {},
  amount: null,
  reason: null,
})

export default {
  name: 'CreateVoucherDetail',
  props: {
    value: {
      type: Object,
      default: inputVars,
    },
    editData: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      ...inputVars(),
      ...accountCodeGetVars,
      ...parentCodeGetVars,
    }
  },
  created () {
    this.getAccountCode()
    this.getParentCodes()
  },
  computed: {
    voucherId () {
      return this.$route.params.voucherId || null
    },
    accountCodeListFiltered () {
      if (this.$objectEmpty(this.parentCode)) return this.accountCodeList
      return this.accountCodeList.filter(item => item.parent_id === this.parentCode.id)
    },
  },
  watch: {
    id () {
      this.updateValue()
    },
    accountCode () {
      this.updateValue()
    },
    amount () {
      this.updateValue()
    },
    reason () {
      this.updateValue()
    },
    voucherId () {
      this.updateValue()
    },
    value (val) {
      this.populateInputs(val)
    },
    editData (val) {
      this.populateInputs(val)
    },
  },
  methods: {
    getParentCodes () {
      const handler = parentCodeGetHandler
      const repository = accountCodeRepository.parent
      handler.setVue(this)
      handler.execute(repository)
    },
    getAccountCode () {
      const handler = accountCodeGetHandler
      const repository = accountCodeRepository.children
      handler.setVue(this)
      handler.execute(repository)
    },
    getAccountCodeParams () {
      return {
        noHas: ['children'].join(','),
      }
    },
    updateValue () {
      const data = this.getInputFields()
      this.$emit('input', data)
    },
    populateInputs (data) {
      if (this.$objectEmpty(data)) {
        this.reset()
        return
      }
      this.update(data)
    },
    update (data) {
      Object.keys(data)
        .concat(['voucherId'])
        .forEach(key => {
          if (key === 'accountCode' && data[key].id === this[key].id) return
          if (this[key] === data[key]) return
          this[key] = data[key]
        })
    },
    reset () {
      const defaults = inputVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
    getInputFields () {
      return Object.keys(inputVars())
        .filter(key => key !== 'parentCode')
        .concat('voucherId')
        .reduce((accum, key) => {
          if (key === 'id' && !this[key]) {
            accum[key] = indexKeyGenerator()
            return accum
          }
          accum[key] = this[key]
          return accum
        }, {})
    },
    convertParentCode (code) {
      return code.toString().padEnd(4, '0')
    },
  },
  mixins: [
    require('@/views/admin/vouchers/account-codes/mixin').default,
  ],
}
</script>