const methods = {
  convertToParentCode ({ code }) {
    return `${code}`.padEnd(2, '0') + '.00'
  },
  convertToAccountCode ({ parent, code }) {
    return `${parent.code.toString().padStart(2, '0')}.${code.toString().padStart(2, '0')}`
  },
}

export default {
  methods,
}