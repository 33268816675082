import { v1 as uuidv1 } from 'uuid'

function getRandomInt (max) {
  return Math.floor(Math.random() * Math.floor(max))
}

function nodeVals () {
  const node = []
  for (let i = 0; i < 6; i++) {
    node.push(getRandomInt(255))
  }
  return node
}

export default function () {
  const v1options = {
    node: nodeVals(),
    clockseq: getRandomInt(16383),
    msecs: Date.now(),
    nsecs: getRandomInt(10000),
  }
  return uuidv1(v1options)
}
